import React, { useState, useEffect } from "react";
import { Spinner, Card } from "react-bootstrap";
import Clock from "../../Clock";
import moment from "moment-timezone"; // Import moment-timezone
import "./Home.css"; // Ensure your CSS file has the necessary styles
import axios from "axios";

const CloudIcon = require('../images/CloudIcon.png'); // Ensure the correct path for CloudIcon
const Cardimage = require('../images/holy-book.jpg'); // Ensure the correct path for CloudIcon

const staticWeatherData = {
  location: { name: "Lahore" },
  current: {
    weather_icons: CloudIcon, // Replace with a valid static image path
    temperature: 25, // Example static temperature
    wind_speed: 10, // Example static wind speed in mph
    cloudcover: 50, // Example static cloud cover percentage
    humidity: 60, // Example static humidity percentage
  },
};



const Home = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingWeather, setLoadingWeather] = useState(true); // Separate loading state for weather
  const [nextPrayer, setNextPrayer] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(()=>{
    console.log(data);
  },[])


  const getWeatherDetail = () => {
    const apiURL = "https://dashboard.zadeashiqanemustafa.com/api/weather";
    axios
      .get(apiURL)
      .then((res) => {
        if (res.data.success === false) {
          setData(staticWeatherData);
        } else {
          setData(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
        setData(staticWeatherData);
      })
      .finally(() => {
        setLoadingWeather(false); // Set loading to false after fetching weather data
      });
  };

  const mphToKph = (mph) => {
      return mph * 1.60934;
  };

  useEffect(() => {
      getWeatherDetail();
  }, []);


  const findNextPrayer = () => {
    const now = moment().tz("Asia/Karachi"); // Use PKT time for calculations
    const upcomingPrayers = prayerTimes
      .map((prayer) => ({ ...prayer, time: moment(prayer.time, "HH:mm") }))
      .filter((prayer) => prayer.time.isAfter(now));

    if (upcomingPrayers.length > 0) {
      const nextPrayer = upcomingPrayers[0];
      setNextPrayer(nextPrayer);
      const timeDiff = moment.duration(nextPrayer.time.diff(now));
      setTimeRemaining(
        `${timeDiff.hours()}h ${timeDiff.minutes()}m ${timeDiff.seconds()}s`
      );
    } else {
      setNextPrayer(null);
      setTimeRemaining("No more prayers today");
    }
  };

  useEffect(() => {
    const timeIntervalId = setInterval(() => {
    //   setCurrentTime(moment().tz("Asia/Karachi").format("hh:mm A")); 
    }, 1000); 

    findNextPrayer();

    const prayerIntervalId = setInterval(() => {
      findNextPrayer();
    }, 60000); // **Changed** to run every minute instead of every second

    setLoading(false); // **Changed**: Set loading to false once initial data is processed

    return () => {
      clearInterval(timeIntervalId);
      clearInterval(prayerIntervalId);
    };
  }, []);


  


  const prayerTimes = [
    { name: "Fajr", time: "05:12" },
    { name: "Dhuhr", time: "12:34" },
    { name: "Asr", time: "16:44" },
    { name: "Maghrib", time: "18:12" },
    { name: "Isha", time: "19:44" },
  ];

  return (
      <>
          <div className="row  align-items-center justify-content-center mt-5 mb-5">
              <div className="col-lg-6 d-flex justify-content-center">
                  <div style={{ maxWidth: "400px", margin: "auto" }}>
                      <Clock />
                  </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center mt-sm-4 mb-sm-4">
                  {loadingWeather ? (
                      <div className="spinner-container">
                          <Spinner
                              animation="border"
                              variant="black"
                              style={{ width: "50px", height: "50px" }}
                          />
                      </div>
                  ) : (
                      <div className="weather-container  my-5">
                          <div className="row">
                              <div className="col-6 d-flex align-items-center">
                                  <div>
                                      <img
                                          src={data?.current?.weather_icons}
                                          alt="Cloud-Icon"
                                          className="icon"
                                          style={{
                                              width: "50px",
                                              height: "50px",
                                          }} // Adjust size as needed
                                      />
                                  </div>
                                  <div className=" ms-3">
                                      <h5
                                          style={{
                                              fontSize: "2rem",
                                              color: "black",
                                          }}
                                      >
                                          {data.location?.name}
                                      </h5>
                                      <h5
                                          style={{
                                              fontSize: "2em",
                                              fontWeight: "bold",
                                              color: "black",
                                          }}
                                      >
                                          {data?.current?.temperature}°C
                                      </h5>
                                  </div>
                              </div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-12">
                                  <div className=" d-flex justify-content-around">
                                      <div>
                                          WindSpeed:{" "}
                                          {mphToKph(
                                              data?.current?.wind_speed
                                          ).toFixed(0)}{" "}
                                          Km/h
                                      </div>
                                      <div className="mx-2">
                                          Clouds: {data?.current?.cloudcover}%
                                      </div>
                                      <div>
                                          Humidity: {data?.current?.humidity}%
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  )}
              </div>
          </div>

          <div className="row align-items-center justify-content-center mb-5">
            {prayerTimes.map((prayer, index) => (
              <Card
                key={index}
                className={`prayer-card m-2 ${
                  nextPrayer?.name === prayer.name ? "next-prayer-card" : ""
                } `}
                style={{
                  width: nextPrayer?.name === prayer.name ? "250px" : "200px",
                  height: nextPrayer?.name === prayer.name ? "150px" : "100px",
                  backgroundColor: nextPrayer?.name === prayer.name ? "transparent" : "#1C3D69",
                  color: "white",
                  backgroundImage: nextPrayer?.name === prayer.name ? `url(${Cardimage})` : "none",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  fontWeight: "bold",
                }}
              >
                <Card.Body className="prayer-card-body">
                  {nextPrayer?.name === prayer.name ? (
                    <Card.Title style={{ fontSize: "1.2rem" }}>
                      Upcoming Prayer: {prayer.name}
                    </Card.Title>
                  ) : (
                    <Card.Title style={{ fontSize: "1.2rem", color: "white" }}>
                      {prayer.name}
                    </Card.Title>
                  )}
                  <Card.Text style={{ fontSize: "1rem", color: "white" }}>
                    {moment(prayer.time, "HH:mm").format("hh:mm A")}
                  </Card.Text>
                  {nextPrayer?.name === prayer.name && (
                    <Card.Text style={{ fontSize: "0.9rem", color: "white" }}>
                      Time Remaining: {timeRemaining}
                    </Card.Text>
                  )}
                </Card.Body>
              </Card>
            ))}
          </div>
      </>
  );
};

export default Home;
